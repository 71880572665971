import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../store/context';
import { CaseStudyData } from '../../mock/SiteData';
import { CaseStudyContent } from '../../sections/CaseStudyContent/CaseStudyContent';
import SEO from '../../components/SearchEngineOptimizations/SearchEngineOptimizations';
import GenericHero from '../../sections/GenericHero/GenericHero';
import { CaseStudyResults } from '../../sections/CaseStudyResults/CaseStudyResults';
import useTriggerLightTheme from '../../hooks/useTriggerLightTheme';
import NotFoundPage from '../404';

const caseStudy = CaseStudyData.find(m => m.id === 'nytimes');

const NyTimes = () => {
    const { navbarHeight, setCurrentPage } = useContext(AppContext);
    const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

    if (!caseStudy) {
        return <NotFoundPage />;
    }

    const {
        hero: { image, title, heroDescriptionText, bgImg, projectSection },
        imgAlt,
        pageDescription,
        technologies,
        technologyDescription,
        descriptionItems,
        externalLink,
    } = caseStudy as any;

    const animationOffset = navbarHeight / 2 + 10;
    useTriggerLightTheme(projectWhiteSectionRef.current, animationOffset, animationOffset);

    useEffect(() => {
        setCurrentPage('Case Study');
    }, []);

    return (
        <main className="page page--case-study">
            <GenericHero
                pageDescription={pageDescription}
                descriptionText={heroDescriptionText}
                imgAlt={imgAlt}
                imgSrc={image}
                title={title}
                bgImg={bgImg}
                projectSection={projectSection}
                paralaxTranslateY={[20, -20]}
                externalLink={externalLink}
            />
            <div ref={projectWhiteSectionRef} className="project-page__white-section">
                <CaseStudyContent
                    contentItems={descriptionItems}
                    technologies={technologies}
                    technologyDescription={technologyDescription}
                />
            </div>
            <CaseStudyResults />
        </main>
    );
};

export default NyTimes;

export const Head = () => {
    if (caseStudy) {
        return (
            <SEO
                title="Loop3 Studio - New York Times"
                description={`${caseStudy.hero.heroDescriptionText}`}
            />
        );
    }
};
